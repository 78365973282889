import React, { useEffect, useState } from 'react';

const Home  = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHtmlContent = async () => {
      const response = await fetch(`home.html`);
      const html = await response.text();
      setHtmlContent(html);
      document.open();
        document.write(html);
        document.close();
    };

    fetchHtmlContent();
  }, []);
  
  return (
    <div />
  );
};


export default Home;