import React, { useEffect, useState } from 'react';
import { getRecentPosts } from '../../services/api';

const Feed = (props) => {
  const { filter, setFilter } = props;
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await getRecentPosts(page, filter.category, filter.searchQuery, filter.keyword);
      const data = response.data;
      setPosts(data.posts);
      setTotalPages(data.totalPages);
    };
    fetchPosts();
  }, [page,filter]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const getSentimentEmoji = (sentiment) => {
    switch (sentiment.toLowerCase()) {
      case 'positive':
        return '👍';
      case 'negative':
        return '⛔';
      case 'neutral':
        return '🤔';
      default:
        return '';
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtons = 5;
    const ellipsis = <span className="mx-1">...</span>;

    if (totalPages <= maxButtons) {
      // Show all buttons if total pages is less than or equal to maxButtons
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 mx-1 rounded-md ${page === i ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show first 3 buttons
      for (let i = 1; i <= 3; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 mx-1 rounded-md ${page === i ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
          >
            {i}
          </button>
        );
      }

      // Show ellipsis if current page is more than 3
      if (page > 3) {
        buttons.push(ellipsis);
      }

      // Show middle 3 buttons with current page
      const start = Math.max(page - 1, 4);
      const end = Math.min(start + 2, totalPages - 2);
      for (let i = start; i <= end; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 mx-1 rounded-md ${page === i ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
          >
            {i}
          </button>
        );
      }

      // Show ellipsis if current page is less than totalPages - 2
      if (page < totalPages - 2) {
        buttons.push(ellipsis);
      }

      // Show last 3 buttons
      for (let i = totalPages - 2; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 mx-1 rounded-md ${page === i ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
          >
            {i}
          </button>
        );
      }
    }

    return buttons;
  };

  return (
    <div>
      <h1 className="font-bold">Feed</h1>
      <table className="w-full mt-4">
        <thead>
          <tr className="text-left">
            <th className="font-bold">Mention summary</th>
            <th className="font-bold">Sentiment</th>
            <th className="font-bold">Date / Time</th>
            <th className="font-bold">Action</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post) => (
            <tr key={post.AlertID}>
              <td>{post.PostSummary}</td>
              <td>
                {post.PostSentiment.charAt(0).toUpperCase() + post.PostSentiment.slice(1)} {getSentimentEmoji(post.PostSentiment)}
              </td>
              <td>{new Date(post.PostDateTime).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}</td>
              <td>
                <a style={{color: 'blue'}} href={post.PostLink} target="_blank" rel="noopener noreferrer">
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination flex justify-center mt-4" style={{ maxWidth: '100%' }}>
        {renderPaginationButtons()}
      </div>
    </div>
  );
};

export default Feed;
