import React, { createContext, useState, useEffect } from 'react';
import { checkAuth } from '../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authenticate = async () => {
      try {
        const response = await checkAuth();
        setUser(response.data);
      } catch (error) {
        setUser(null);
      }
      setLoading(false);
    };
    authenticate();
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
