import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Unauthorized from './Unauthorized';

const Authorized = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) return <div>Loading...</div>;

  if (!user) return <Unauthorized />;

  return <>{children}</>;
};

export default Authorized;
