import React from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Feed from './Feed';
import Stats from './Stats';
import { AuthContext } from '../../context/AuthContext';


const Dashboard = () => {
  const { keyword } = useParams();
  const { user, loading } = React.useContext(AuthContext);
  const [filter, setFilter] = React.useState({
    category: 'all',
    keyword: keyword || 'all',
    searchQuery: '',
  });
  React.useEffect(() => {
    if (keyword) {
      setFilter({ ...filter, keyword });
    }
  }
  , [keyword]);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col flex-grow">
        <Header filter={filter} setFilter={setFilter} />
        <div className="flex-grow p-6">
          <Stats filter={filter} setFilter={setFilter} />
          <Feed filter={filter} setFilter={setFilter} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
