import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Auth/Login';
import Unauthorized from './components/Auth/Unauthorized';
import Dashboard from './components/Dashboard/Dashboard';
import Authorized from './components/Auth/Authorized';
import { AuthProvider } from './context/AuthContext';
import Home from './components/Home';
import AdminDashboard from './components/Admin';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path='/admin' element={<AdminDashboard />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path='/feed/:keyword' element={<Dashboard />} />
          <Route
            path="*"
            element={
              <Authorized>
                <Dashboard />
              </Authorized>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
