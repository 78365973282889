import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: BACKEND_URL+'/api',
  withCredentials: true,
});

export const signInWithSlack =  () => {
  const location = `${api.defaults.baseURL}/auth/slack`;
  const newTab = window.open(location, '_blank');
  
  if (newTab) {
    const checkTabClosed = setInterval(() => {
      if (newTab.closed) {
        clearInterval(checkTabClosed);
        window.location.href = '/dashboard';
      }
    }, 1000); 
  } else {
    alert('Popup blocked');
    console.error('Popup blocked');
  }
};

export const checkAuth = async () => {
  return api.get('/auth/check', { withCredentials: true });
};

export const getRecentPosts = async (page = 1, postCategory = "all", searchQuery = "", keyword = "") => {
  let url = `/posts/recentPosts?page=${page}&postCategory=${postCategory}`;
  if (searchQuery.length > 0) {
    url += `&searchQuery=${searchQuery}`;
  }
  if (keyword.length > 0) {
    url += `&keyword=${keyword}`;
  }
  return api.get(url, { withCredentials: true });
};

export const getUserKeywords = async () => {
  return api.get('/keywords/myKeywords', { withCredentials: true });
};

export const getStats = async (keyword = "", searchQuery = "") => {
  let url = `/posts/stats?keyword=${keyword}`;
  if (searchQuery.length > 0) {
    url += `&searchQuery=${searchQuery}`;
  }
  return api.get(url, { withCredentials: true });
};

