import React, { useState, useEffect } from 'react';
import KeywordForm from './AddKeywords';
import axios from 'axios';
import ListUsers from './ListUsers';

function Sidebar({ onNavigate }) {
  return (
    <div className="w-64 bg-gray-100 h-screen fixed left-0 top-0 p-4">
      <h1 className="text-2xl font-bold mb-8">Zakk.ai</h1>
      <nav>
        <ul>
          <li className="mb-2"><button onClick={() => onNavigate('add')} className="text-gray-700 hover:text-purple-700">Add Keyword</button></li>
          <li className="mb-2"><button onClick={() => onNavigate('list')} className="text-gray-700 hover:text-purple-700">List Keywords</button></li>
          <li className="mb-2"><button onClick={() => onNavigate('users')} className="text-gray-700 hover:text-purple-700">List Users</button></li>
        </ul>
      </nav>
    </div>
  );
}

function KeywordList({ apiKey }) {
    const [keywords, setKeywords] = useState([]);
  
    useEffect(() => {
      const fetchKeywords = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/listKeywords`, { key: apiKey });
            if (response.status === 200) {
                const data = response.data;
                setKeywords(data);
            } else {
                alert('Failed to fetch keywords');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred');
        }
      };
  
      fetchKeywords();
    }, [apiKey]);
  
    return (
      <div className="bg-white p-4 rounded shadow">
        <h2 className="text-xl font-semibold mb-4">Keyword List</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ID
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Keyword
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created At
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Updated At
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {keywords.map((keyword) => (
              <tr key={keyword.KeywordID}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {keyword.KeywordID}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {keyword.Keyword}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(keyword.createdAt).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(keyword.updatedAt).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

function Dashboard() {
  const [apiKey, setApiKey] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [currentView, setCurrentView] = useState('add');

const verifyApiKey = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/verify`, { key: apiKey });
        if (response.status === 200) {
            setIsVerified(true);
        } else {
            alert('Invalid API key');
        }
    } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
    }
};

  if (!isVerified) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="bg-white p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold mb-4">Enter API Key</h2>
          <input
            type="text"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            className="w-full px-3 py-2 border rounded mb-4"
            placeholder="API Key"
          />
          <button onClick={verifyApiKey} className="bg-purple-700 text-white px-4 py-2 rounded w-full">
            Verify
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex">
      <Sidebar onNavigate={setCurrentView} />
      <main className="ml-64 p-8 flex-grow">
        <h1 className="text-3xl font-bold mb-8">
          {currentView === 'add' ? 'Add Keyword' : currentView === 'list' ? 'List Keywords' : 'List Users'}
        </h1>
        {currentView === 'add' ? (
          <KeywordForm apiKey={apiKey} />
        ) : currentView === 'list' ? (
          <KeywordList apiKey={apiKey} />
        ): <ListUsers apiKey={apiKey} />}
      </main>
    </div>
  );
}

export default Dashboard;