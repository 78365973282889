import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Sidebar = () => {
  const [keywords, setKeywords] = React.useState([]);

  React.useEffect(() => {
    // Make API call to fetch keywords
    // Replace API_URL with the actual API endpoint
    const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/keywords/myKeywords';
    axios.get(API_URL, { withCredentials: true }).then(response => {
      setKeywords(response.data);
    }
    ).catch(error => {
      console.error('Error fetching keywords: ', error);
    });
  }, []);

  return (
    <div className="w-64 h-screen bg-gray-100">
      <div className="p-6">
        <h1 className="text-2xl font-bold">Zakk.ai</h1>
        <nav className="mt-6">
          {/* <span className="block px-4 py-2 mt-2 text-sm text-gray-400 rounded cursor-not-allowed">
            My agents
          </span> */}
          <Link to="/dashboard" className="block px-4 py-2 mt-2 text-sm text-gray-700 rounded hover:bg-gray-200">
            Feed
          </Link>
          {keywords.map(keyword => (
            <Link
              key={keyword.KeywordID}
              to={`/feed/${keyword.Keyword}`}
              className="block px-6 py-2 mt-2 text-sm text-gray-500 rounded hover:bg-gray-200"
            >
              {keyword.Keyword}
            </Link>
          ))}
          {/* <span className="block px-4 py-2 mt-2 text-sm text-gray-400 rounded cursor-not-allowed">
            Q&A
          </span> */}
          {/* <span className="block px-4 py-2 mt-2 text-sm text-gray-400 rounded cursor-not-allowed">
            Insights
          </span> */}
          <a href={process.env.REACT_APP_BACKEND_URL+"/api/auth/slack/install"} style={{alignItems:'center',color:"#fff",backgroundColor:"#4A154B", marginTop: "20px",
            border: '0', borderRadius: '4px', display: 'inline-flex', fontFamily: 'Lato, sans-serif', fontSize: '16px', fontWeight: '600', height: '48px', justifyContent: 'center', textDecoration: 'none', width: '204px' 
          }} ><svg xmlns="http://www.w3.org/2000/svg" style={{height:'20px',width:'20px',marginRight:'12px'}} viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>Add to Slack</a>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
