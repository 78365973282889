
import axios from 'axios';
import React, { useState } from 'react';

function KeywordForm({ apiKey }) {
  const [email, setEmail] = useState('');
  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState([]);

  const fetchKeywords = async () => {
    if (!email) {
      alert('Please enter an email address');
      return;
    }

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/listUserKeywords`, {
      key: apiKey,
      email
    }).then((response) => {
      if (response.status === 200) {
        setKeywords(response.data);
      } else {
        alert('Failed to fetch keywords');
      }
    }).catch((error) => {
      console.error('Error:', error);
      alert('An error occurred');
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/addKeyword`, {
        key: apiKey,
        email,
        keyword
      }).then((response) => {
        if (response.status === 200) {
          alert('Keyword added successfully');
          fetchKeywords();
        } else {
          alert('Failed to add keyword. '+ response.data.error);
        }
      }).catch((error) => {
        console.error('Error:', error);
        alert('An error occurred while adding keyword');
      });
    
  };

  return (
    <div className="bg-white p-4 rounded shadow">
      <form onSubmit={handleSubmit} className="mb-4">
        <h2 className="text-xl font-semibold mb-4">Add Keyword</h2>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-1">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="keyword" className="block mb-1">Keyword</label>
          <input
            type="text"
            id="keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="flex space-x-2">
          <button type="submit" className="bg-purple-700 text-white px-4 py-2 rounded">
            Add Keyword
          </button>
          <button type="button" onClick={fetchKeywords} className="bg-blue-500 text-white px-4 py-2 rounded">
            Show User's Keywords
          </button>
        </div>
      </form>

      {keywords.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold mb-2">User's Keywords:</h3>
          <div className="flex flex-wrap gap-2">
            {keywords.map((kw) => (
              <span key={kw.KeywordID} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm">
                {kw.Keyword}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default KeywordForm;