import React from 'react';

const Unauthorized = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <p className="text-red-500">You are not authorized to access this application.</p>
    </div>
  );
};

export default Unauthorized;
