import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
// import SearchBoxComponent from './SearchBar';


const Header = (props) => {
  const { filter, setFilter } = props;
  const { user, loading } = useContext(AuthContext);
  const firstName = user ? user.Name.split(" ")[0] : '';
  const avatar = user && user.Avatar ? user.Avatar : '/images/user.png';
  console.log(user);
  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      setFilter({ ...filter, searchQuery: event.target.value });
    }
  };

  const handleClearSearch = () => {
    document.getElementById('search').value = '';
    setFilter({ ...filter, searchQuery: '' });
  };

  return (
    <header className="flex items-center justify-between p-4 bg-white border-b">
      <div className="flex items-center" style={{ width: "100%" }}>
        <input
          style={{ width: '88%' }}
          id='search'
          type="text"
          placeholder="Search"
          className="px-4 py-2 mr-4 text-gray-400"
          onKeyDown={handleSearch}
        />
        {/* <SearchBoxComponent /> */}
        <button style={{width: "30px",paddingRight: "5px"}} onClick={handleClearSearch} className="text-gray-400">
          {
            filter.searchQuery && filter.searchQuery.length > 0 ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                aria-describedby="desc" role="img" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Close</title>
                <path data-name="layer1"
                  fill="none" stroke="#202020" strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" d="M41.999 20.002l-22 22m22 0L20 20"></path>
              </svg>
            ) : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
              aria-describedby="desc" role="img" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Enter Key</title>
                <rect data-name="layer2"
                  x="2" y="2" width="60" height="60" rx="7.8" ry="7.8" fill="none" stroke="#202020"
                  strokeLinejoin="round" strokeLinecap="round" strokeWidth="2"></rect>
                <path data-name="layer1" fill="none" stroke="#202020" strokeLinejoin="round"
                  strokeLinecap="round" strokeWidth="2" d="M16 32h30v-8"></path>
                <path data-name="layer1" fill="none" stroke="#202020" strokeLinejoin="round"
                  strokeLinecap="round" strokeWidth="2" d="M24 40l-8-8 8-8"></path>
              </svg>
          }
        </button>
        <Link to="/profile" className="flex items-center">
          <img
            src={avatar}
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
          <span className="ml-2 text-sm">{firstName}</span>
        </Link>
      </div>
    </header>
  );
};

export default Header;
