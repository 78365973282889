import React, { useEffect, useState } from 'react';
import { getStats } from '../../services/api';

const Stats = (props) => {
  const { filter, setFilter } = props;
  console.log(filter);

  const [stats, setStats] = useState({
    support: 0,
    suggestions: 0,
    discussions: 0,
    endorsements: 0,
    supportChange: 0,
    suggestionsChange: 0,
    discussionsChange: 0,
    endorsementsChange: 0,
  });

  const handleCategoryClick = (category) => {
    console.log(category);
    if (filter.category === category) {
      setFilter({...filter, category: 'all'});
    } else {
      setFilter({...filter, category: category});
    }
  };

  useEffect(() => {
    const fetchStats = async () => {
      const response = await getStats(filter.keyword, filter.searchQuery);
      setStats(response.data);
    };
    fetchStats();
  }, [filter]);

  return (
    <div className="">
      <div className="flex items-center mb-8 pb-5 border-b">
        <h1 className="text-lg font-bold">Overview</h1>
      </div>
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div
          className={`p-4 rounded shadow ${
            filter.category === 'support' ? 'bg-gray-200' : 'bg-white'
          }`}
          onClick={() => handleCategoryClick('support')}
          style={{ cursor: 'pointer' }}
        >
          <h2 className="text-lg font-bold">Support</h2>
          <p className="text-2xl">{stats.support}</p>
          <p
            className={
              stats.supportChange >= 0 ? 'text-green-500' : 'text-red-500'
            }
          >
            {stats.supportChange >= 0 ? '↑' : '↓'}{' '}
            {Math.abs(stats.supportChange)}% today
          </p>
        </div>
        <div
          className={`p-4 rounded shadow ${
            filter.category === 'suggestions' ? 'bg-gray-200' : 'bg-white'
          }`}
          onClick={() => handleCategoryClick('suggestions')}
          style={{ cursor: 'pointer' }}
        >
          <h2 className="text-lg font-bold">Suggestions</h2>
          <p className="text-2xl">{stats.suggestions}</p>
          <p
            className={
              stats.suggestionsChange >= 0 ? 'text-green-500' : 'text-red-500'
            }
          >
            {stats.suggestionsChange >= 0 ? '↑' : '↓'}{' '}
            {Math.abs(stats.suggestionsChange)}% today
          </p>
        </div>
        <div
          className={`p-4 rounded shadow ${
            filter.category === 'discussions' ? 'bg-gray-200' : 'bg-white'
          }`}
          onClick={() => handleCategoryClick('discussions')}
          style={{ cursor: 'pointer' }}
        >
          <h2 className="text-lg font-bold">Discussions</h2>
          <p className="text-2xl">{stats.discussions}</p>
          <p
            className={
              stats.discussionsChange >= 0 ? 'text-green-500' : 'text-red-500'
            }
          >
            {stats.discussionsChange >= 0 ? '↑' : '↓'}{' '}
            {Math.abs(stats.discussionsChange)}% today
          </p>
        </div>
        <div
          className={`p-4 rounded shadow ${
            filter.category === 'endorsements' ? 'bg-gray-200' : 'bg-white'
          }`}
          onClick={() => handleCategoryClick('endorsements')}
          style={{ cursor: 'pointer' }}
        >
          <h2 className="text-lg font-bold">Endorsements</h2>
          <p className="text-2xl">{stats.endorsements}</p>
          <p
            className={
              stats.endorsementsChange >= 0 ? 'text-green-500' : 'text-red-500'
            }
          >
            {stats.endorsementsChange >= 0 ? '↑' : '↓'}{' '}
            {Math.abs(stats.endorsementsChange)}% today
          </p>
        </div>
      </div>
    </div>
  );
};

export default Stats;
